
.hamburger-btn {
    @apply bg-black text-white p-4 rounded-full;
  }
  
  .dropdown-menu {
  
    transition: visibility 0.25s, opacity 0.25s linear;
    z-index: 0;
  }
  
  .dropdown-menu.hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  