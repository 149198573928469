.amplify-input {
  height: 30px;
}

.amplify-textfield {
  font-size: 0.85rem;
}

.amplify-tabs-item {
  padding: 0.5rem;
  font-size: 0.875rem;
  color: #15254e;
}

.amplify-heading {
  text-align: center;
  color: #15254e;
}

button.amplify-button.amplify-field-group__control.amplify-field__show-password {
  /* Your CSS styles here */
  padding: 5px;
}

button.amplify-button.amplify-field-group__control.amplify-button--primary {
  padding: 0.4rem;
  margin-top: 20px;
  border-radius: 0.5rem;
}

button.amplify-button.amplify-field-group__control.amplify-button--primary:hover {
  padding: 0.4rem;
  margin-top: 20px;
  border-radius: 0.5rem;
  opacity: 0.5;
}