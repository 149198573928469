@keyframes scaling {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.05); /* Slightly bigger size */
  }
  60% {
    transform: scale(1.04); /* Smooth transition towards original size */
  }
  70% {
    transform: scale(1.03); /* Smooth transition towards original size */
  }
  80% {
    transform: scale(1.02); /* Smooth transition towards original size */
  }
  90% {
    transform: scale(1.01); /* Smooth transition towards original size */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

.scaling-animation {
  display: inline-block;
  animation: scaling 4s linear 2;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}